import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { rhythm } from "../../utils/typography"

const PortfolioIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="History" />
      <div style={{ margin: `auto`, maxWidth: rhythm(34) }}>
        <h1 style={{ textAlign: `center` }}>History</h1>
      </div>
      <div className="portfolio-grid" style={{ justifyContent: "center" }}>
        <article
          className="portfolio-entry"
          style={{ minWidth: "60%", maxWidth: "780px", width: "100%" }}
        >
          <div className="banner-wrapper">
            <Image
              fluid={data.mastOne.childImageSharp.fluid}
              alt="Me"
              style={{
                maxWidth: `100%`,
                marginBottom: rhythm(1 / 2),
                marginLeft: `auto`,
                marginRight: `auto`,
                marginTop: 0,
              }}
            />
          </div>
          <header>
            <h3
              style={{
                marginTop: rhythm(1 / 4),
                marginBottom: rhythm(1 / 4),
              }}
            >
              Mary Anning: The Forgotten Fossil Hunter
            </h3>
          </header>
          <section style={{ marginBottom: "1rem" }}>
            Producer | Director | 2nd Camera | Editor
          </section>
          <section>
            <p>
              Born in Lyme Regis in 1799, Mary Anning was a pioneering
              palaeontologist and fossil collector who's story continues to
              inspire so many scientists to this day. In this documentary, Dr
              Anjana Khatwa, Dr Liz Hide, David Tucker and Anya Pearson explore
              Anning's life and legacy.
            </p>
            <p>
              <a href="https://access.historyhit.com/videos/mary-anning-forgotten-fossil-hunter">
                Watch the full documentary on History Hit
              </a>
            </p>
          </section>
        </article>

        <article
          className="portfolio-entry"
          style={{ minWidth: "60%", maxWidth: "780px", width: "100%" }}
        >
          <div className="banner-wrapper">
            <Image
              fluid={data.mastTwo.childImageSharp.fluid}
              alt="Me"
              style={{
                maxWidth: `100%`,
                marginBottom: rhythm(1 / 2),
                marginLeft: `auto`,
                marginRight: `auto`,
                marginTop: 0,
              }}
            />
          </div>
          <header>
            <h3
              style={{
                marginTop: rhythm(1 / 4),
                marginBottom: rhythm(1 / 4),
              }}
            >
              Women of the Iranian Revolution
            </h3>
          </header>
          <section style={{ marginBottom: "1rem" }}>
            Producer | Director | Editor
          </section>
          <section>
            <p>
              The Iranian Revolution of 1979, also known as the Islamic
              Revolution, was a series of events that culminated in the
              overthrow of the Pahlavi dynasty under Shah Mohammad Reza Pahlavi,
              and the replacement of his government with an Islamic republic
              under the rule of Ayatollah Ruhollah Khomeini.
            </p>
            <p>
              Of those who lived in Iran during the Revolution, resisted such
              invasive laws and were exiled or imprisoned as a result, three
              woman live to tell the tale. Shahin Navai, Diana Nammi and Nasrin
              Parvaz all fought for freedom, justice and equal rights in 1979
              and continue to do so to this day.
            </p>
            <p>
              <a href="https://access.historyhit.com/videos/1979-women-of-the-iranian-revolution">
                Watch the full documentary on History Hit
              </a>
            </p>
          </section>
        </article>

        <article
          className="portfolio-entry"
          style={{ minWidth: "60%", maxWidth: "780px", width: "100%" }}
        >
          <div className="banner-wrapper">
            <Image
              fluid={data.mastThree.childImageSharp.fluid}
              alt="Me"
              style={{
                maxWidth: `100%`,
                marginBottom: rhythm(1 / 2),
                marginLeft: `auto`,
                marginRight: `auto`,
                marginTop: 0,
              }}
            />
          </div>
          <header>
            <h3
              style={{
                marginTop: rhythm(1 / 4),
                marginBottom: rhythm(1 / 4),
              }}
            >
              Sir Joseph Banks: Pioneer of British Botany
            </h3>
          </header>
          <section style={{ marginBottom: "1rem" }}>
            Producer | Director | 2nd Camera | Editor
          </section>
          <section>
            <p>
              'Dictator of British Botany'. 'Autocrat of the Philosophers'. Sir
              Joseph Banks has been called many things over the past few
              centuries. A towering figure in the development of British botany
              and British natural history during the 18th century, he voyaged
              across the World with famous navigators such as Captain Cook,
              visiting far away lands such as Australia and bringing back a
              plethora of new plant species to Britain.
            </p>
            <p>
              In this documentary Jordan Goodman and the Natural History
              Museum's Dr Mark Carine tell the story of Joseph Banks and
              highlight why he has such a dominant legacy in the development of
              British botany.
            </p>
            <p>
              <a href="https://access.historyhit.com/dan-snow/season:1/videos/sir-joseph-banks-pioneer-of-british-botany">
                Watch the full documentary on History Hit
              </a>
            </p>
          </section>
        </article>
      </div>
      <div style={{ textAlign: `center` }}>
        <Link to={`/portfolio`} className="button">
          Back to Portfolio
        </Link>
      </div>
    </Layout>
  )
}

export default PortfolioIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    mastOne: file(absolutePath: { regex: "/mary-anning.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    mastTwo: file(
      absolutePath: { regex: "/women-of-the-iranian-revolution.jpg/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    mastThree: file(absolutePath: { regex: "/joseph-banks.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
